import { template as template_b91d76e28c5b4e2c82df77819fa4ab66 } from "@ember/template-compiler";
const FKLabel = template_b91d76e28c5b4e2c82df77819fa4ab66(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
