import { template as template_d22743e7b90c4e28bf3b677f0fdbe3f1 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const SidebarSectionHeader = template_d22743e7b90c4e28bf3b677f0fdbe3f1(`
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      @forwardEvent={{true}}
      aria-controls={{@sidebarSectionContentId}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionHeader;
